<template>
  <div class="pa-2">
    <v-card class="pa-2 mb-2" flat>
     
        <v-row>



<v-radio-group
      v-model="rdo"
      row
      class="pa-2"
      mandatory
    >
      <v-radio
        label="Application Id"
        value="appid"
        
      ></v-radio>
      <v-radio
        label="Barcode"
        value="barcode"
      ></v-radio>
    </v-radio-group>

            <!-- <v-text-field
              v-model="txtbarcodesearch"
              outlined
              dense
              label="Enter Barcode"
              class="pt-1"
            ></v-text-field> -->
         
<!-- <v-col cols="12" md="1">
    <div class="text-center pt-2" style="font-size:1rem">OR</div>
</v-col> -->
          <v-col cols="12" md="4">
            <v-text-field
              v-model="txtsearch"
              outlined
              dense
              label=""
              class="pt-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn medium color="primary" @click="btnsearch" class="mt-1">
              <v-icon left>mdi-reload</v-icon>Load Data</v-btn
            >
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
     
    </v-card>
    <!-- <v-row justify="center" no-gutters v-if="displaystatus == 'true'">
      <v-col cols="12" class="pa-2 mb-2" flat> -->
        <v-card class="mx-auto" outlined v-if="displaystatus == 'true'">
          <v-row class="pa-5">
            <v-col cols="12" md="8">
              <v-row dense>
                <v-col cols="4">App ID</v-col>
                <v-col cols="8">{{ appData.application_id }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Name</v-col>
                <v-col cols="8"
                  >{{ appData.name }} {{ appData.lastName }}</v-col
                >
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Company</v-col>
                <v-col cols="8">{{ appData.company_name }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Designation</v-col>
                <v-col cols="8">{{ appData.role_name }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

                 <v-row dense>
                <v-col cols="4">Unique ID</v-col>
                <v-col cols="8">{{ appData.mName }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>

             

               <v-row dense>
                <v-col cols="4">Barcode</v-col>
                <v-col cols="8">{{ appData.barcode }}</v-col>
                <v-col cols="12"><v-divider></v-divider></v-col>
              </v-row>


              <v-row>
                <v-row dense>
                  <!-- <v-col cols="12">
                    <v-checkbox
                      v-model="cbxcheck"
                      label="Do you want to transfer "
                      color="orange"
                    ></v-checkbox>
                  </v-col> -->

 <div class="pa-1">
            


            <v-radio-group
      v-model="rdoeventfire"
      row
      class="pa-2"
      @change="btnrdoevent"
      
     
    >
     
      <v-radio
        label="Rescan"
        value="rescan"
      ></v-radio>
      <v-radio
        label="Blocklist"
        value="block"
      ></v-radio>
      <v-radio
        label="Clear"
        value="clear"
      ></v-radio>
    </v-radio-group>
           
            <!-- <v-btn medium color="success" @click="btnrescan"  class="ml-1">
              RESCAN</v-btn
            >
           
            <v-btn medium color="error"  @click="btnblock"  class="ml-1">
              BLOCK</v-btn
            > -->
            </div>


                </v-row>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <div class="pa-3 text-lg-center">
                <img
                  :src="this.$apiPath + appData.picHeadShot"
                  key="src"
                  class="imgId"
                />
              </div>
            </v-col>
          </v-row>

         
        </v-card>

        
           
         
      <!-- </v-col>
    </v-row> -->
    <v-row v-if="cbxcheck == true">

      <v-col cols="12" md="12" >
         <v-card  class="pa-1 mb-2 mt-2 pt-5" >
      <v-card-actions>
        <v-row v-if="rdoeventfire!='clear'">
          <v-col cols="12" md="6">
          <v-text-field
              v-model="txtbarcode"
              outlined
              dense
              :label="setstatus"
              class="pt-1"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn @click="save" color="primary" v-if="rdoeventfire=='rescan'"> Save </v-btn>
            <v-btn @click="savebloclist" color="primary" v-if="rdoeventfire=='block'"> Save </v-btn>
            <v-btn  color="error" @click="btncancel" class="pa-2 ma-1"> Cancel </v-btn>
          </v-col>
        </v-row>

         <v-row v-if="rdoeventfire=='clear'">
          <v-col cols="12" md="12" >
            <v-alert
            dense
            flat
      
     
     
      colored-border
     
    
    >
      Are you sure to clear this barcode ?
 <v-btn medium color="primary" @click="btncleare" class="ml-1">
              YES</v-btn
            >
            <v-btn medium color="error" @click="btncancel" class="ml-1">
              NO</v-btn
            >
    </v-alert>
          </v-col>
         </v-row>
        
      </v-card-actions>
    </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" timeout="1000"  top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
//import moment from "moment";
//import ViewApplication from "../../components/ViewApplication.vue";
export default {
  data() {
    return {
      setstatus:"",
      rdo:"",
      rdoeventfire:null,
      bgcolor:"",
      searchbarcode:"",
      searchappid:"",
      cbxcheck: false,
      txtsearch: "",
      txtbarcode:"",
      txtbarcodesearch:"",
      TDselected: [],
      desserts: [],
      MediaVenueForUpdate: [],
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      singleSelect: false,
      loadingstatus: false,
      overlay: false,
      displaystatus: "false",
      preferedCity: "",
      appData: {
        application_id: "",
        name: "",
        lastName: "",
        company_name: "",
        role_name: "",
        picHeadShot: "",
        venue_name: "",
        mediaType_name: "",
        status_named: "",
        venue_id:"",
        barcode:"",
        vendor_id:"",
        mName:"",
      },
      TransferData: {
        event_id: "",
        venue_id: "",
        application_id: "",
      },
      clearBarcode:{
        app_id: "",
         event_id: "",
          venue_id: "",
  vendor_id: ""
      },
       postBodybarcode: {
        application_id: "",
        event_id: "",
        venue_id: "",
        vendor_id:"",
        barcode: "",
      },

       postBodybloclist: {
        app_id: "",
         event_id: "",
        venue_id:"",
       vendor_id:"",
        old_barcode: "",
        new_barcode: "",
      },
    };
  },
  mounted() {
    sessionStorage.display_event_name = "IPL T20 2021";
    sessionStorage.display_venue_name = "Chinaswamy Stadium";

    this.$store.commit("setPageTitle", "Local Rescan Barcode");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.displaystatus = "false";
   // this.bindmediaVenue(this.$store.state.eventInfo.EventId);
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },
    btnrdoevent:function(){
      if(this.rdoeventfire=="rescan"){
this.setstatus="Scan barcode for rescan";
this.cbxcheck=false;
  this.cbxcheck=true;
      }
      else
      {
this.setstatus="Scan barcode for blocklist";
  this.cbxcheck=true;
      }
    },

    btnsearch: function () {
    //  alert(this.rdo);
    this.rdoeventfire=null;
    this.cbxcheck=false;
    if(this.rdo=="barcode")
    {
this.searchbarcode=this.txtsearch;
this.searchappid="0";
    }
    else
    {
this.searchappid=this.txtsearch;
this.searchbarcode="0";
    }
     this.Reload(
        this.searchbarcode,
      this.searchappid);
    },

    save: async function () {
      this.postBodybarcode.event_id = this.$store.state.eventInfo.EventId;
      this.postBodybarcode.venue_id = this.$store.state.eventInfo.VenueId;
      if (this.txtbarcode.length <= 0) {
        this.showSnackbar("error", "Scan barcode !");
        return;
      }
this.overlay = true;
     // this.postBodybarcode.application_id = appData.application_id;
      this.postBodybarcode.barcode = this.txtbarcode;
      this.postBodybarcode.vendor_id=this.appData.vendor_id;
      console.log(this.postBodybarcode);
      await this.$axios
        .put("Application/setBarcode", this.postBodybarcode)
        .then((res) => {
          console.log(res.data.message);
          if (res.data.status == "Success") {
            if (res.data.message == null || res.data.message == "") {
            
                this.cbxcheck=false;
               this.Reload(
        this.searchbarcode,
      this.searchappid);
              
              this.showSnackbar("Success", "Barcode Updated !");
            } else if (res.data.message == "FOUND") {
              this.showSnackbar("error", "Barcode already exists");
            } else if (res.data.message == "INACTIVE") {
              this.showSnackbar("error", "Barcode inactive");
            }
          }
        })
        .catch()
        .finally(()=>{
          this.overlay = false;
        });

      this.txtbarcode = "";
   this.rdoeventfire=null;
            this.cbxcheck=false;
      
    },

     savebloclist: async function () {
      this.postBodybloclist.event_id = this.$store.state.eventInfo.EventId;
      this.postBodybloclist.venue_id = this.$store.state.eventInfo.VenueId;
      this.postBodybloclist.app_id = this.appData.application_id;
      this.postBodybloclist.vendor_id = this.appData.vendor_id;

      this.postBodybloclist.old_barcode = this.appData.barcode;
      if (this.txtbarcode.length <= 0) {
        this.showSnackbar("error", "Scan barcode !");
        return;
      }
this.overlay = true;
     // this.postBodybarcode.application_id = appData.application_id;
      this.postBodybloclist.new_barcode = this.txtbarcode;
      console.log(this.postBodybloclist);
      await this.$axios
        .put("Application/InactiveBarcode", this.postBodybloclist)
        .then((res) => {
          console.log(res.data);
          if (res.data.status == "Success") {
            if (res.data.message == null || res.data.message == "") {
            
                this.cbxcheck=false;
               this.Reload(
        this.searchbarcode,
      this.searchappid);
              
              this.showSnackbar("Success", "Barcode Updated !");
            } else if (res.data.message == "FOUND") {
              this.showSnackbar("error", "Barcode already exists");
            } else if (res.data.message == "INACTIVE") {
              this.showSnackbar("error", "Barcode inactive");
            }
          }
        })
        .catch()
        .finally(()=>{
          this.overlay = false;
        });

      this.txtbarcode = "";
 this.txtbarcode = "";
   this.rdoeventfire=null;
      
    },

    btnrescannshow:function(){
      this.cbxcheck=true;
      this.bgcolor="Success";
    },

btnrescan:function(){
this.setstatus="Scan barcode for rescan";
  this.cbxcheck=true;
},
btnblock:function(){
this.setstatus="Scan barcode for block";
  this.cbxcheck=true;
},
btncancel:function(){
this.setstatus="";
  this.cbxcheck=false;
  this.rdoeventfire=null;
},

     btncleare: async function(){
       this.overlay = true;
this.clearBarcode.app_id=this.appData.application_id;
this.clearBarcode.event_id= this.$store.state.eventInfo.EventId;
this.clearBarcode.venue_id= this.$store.state.eventInfo.VenueId;
this.clearBarcode.vendor_id=  this.appData.vendor_id;
console.log(this.clearBarcode);
      await this.$axios
        .put("Application/clearBarcode", this.clearBarcode)
        .then((res) => {
          console.log(res.data);
         //  console.log(res.data.me);
          if (res.data.status == "Success") {

            if(res.data.message==null){
            this.showSnackbar(
              "success",
              "Barcode has been cleared!"
            );
            this.rdoeventfire=null;
            this.cbxcheck=false;
            this.overlay = false;
               this.Reload(
        this.searchbarcode,
      this.searchappid);
          }
       
          }
        });
    },

    

    Reload: async function (barcode, app_id) {
      

      this.loadingstatus = true;
      this.overlay = true;
      await this.$axios
        .get(
          "Application/appByBarcode/" + this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId +  "/" + barcode + "/" + app_id
        )
        .then((res) => {
           console.log(res.data.result);
          this.appData.application_id = "";
          this.appData.application_id = res.data.result.app_id;
          this.appData.name = res.data.result.fName;
          this.appData.lastName = res.data.result.lastName;
          this.appData.company_name = res.data.result.company_name;
          this.appData.role_name = res.data.result.jobtype_name;
          this.appData.barcode = res.data.result.barcode;
          this.appData.picHeadShot = res.data.result.picHeadShot;
          this.appData.venue_name = res.data.result.venue_name;
          this.appData.venue_id = res.data.result.preferedCity;
          this.appData.vendor_id= res.data.result.vendor_id;
          this.appData.mName= res.data.result.mName;

       this.postBodybarcode.application_id= res.data.result.application_id;


          if (this.appData.application_id != null) {
            this.displaystatus = "true";
            this.overlay = false;
            this.loadingstatus = false;
          } else {
            this.displaystatus = "false";
            this.showSnackbar("error", "No Data Found !");
            this.overlay = false;
          }
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style  scoped>
.tdalign {
  text-align: center;
}
.imgId {
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
  cursor: pointer;
  height: 200px;
}
</style>>

